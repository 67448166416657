@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--font-sans: HelveticaNeue, HelveticaNeue Fallback;
		/* --font-mono: here if you got it... */

		/* prefixed with foreground because it should look good on the background */
		--foreground-destructive: 345 82.7% 40.8%;

		--background: 0 0% 100%;
		--foreground: 222.2 84% 4.9%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;

		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;
		--input-invalid: 0 84.2% 60.2%;

		--primary: 126 97% 27%;
		--primary-foreground: 210 40% 98%;

		--secondary: 221 83% 58%;
		--secondary-foreground: 221 100% 100%;

		--accent: 353 100% 45%;  /* meintraktor brand color */
		--accent-foreground: 210 40% 98%;

		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 210 40% 98%;

		--muted: 210 40% 96.1%;
		--muted-foreground: 222.2 47.4% 11.2%;

		--success: 146 100% 31%;
		--success-foreground: 146 100% 100%;

		--ring: 215 20.2% 65.1%;

		--radius: 0.5rem;
	}

	/*
	.dark {
		--background: 222.2 84% 4.9%;
		--foreground: 210 40% 98%;

		/* prefixed with foreground because it should look good on the background *//*
			--foreground-danger: -4 84% 60%;

            --muted: 217.2 32.6% 17.5%;
            --muted-foreground: 215 20.2% 65.1%;

            --popover: 222.2 84% 4.9%;
            --popover-foreground: 210 40% 98%;

            --card: 222.2 84% 4.9%;
            --card-foreground: 210 40% 98%;

            --border: 217.2 32.6% 17.5%;
            --input: 217.2 32.6% 17.5%;
            --input-invalid: 0 62.8% 30.6%;

            --primary: 210 40% 98%;
            --primary-foreground: 222.2 47.4% 11.2%;

            --secondary: 217.2 32.6% 17.5%;
            --secondary-foreground: 210 40% 98%;

            --accent: 217.2 32.6% 10%;
            --accent-foreground: 210 40% 98%;

            --destructive: 0 62.8% 30.6%;
            --destructive-foreground: 0 85.7% 97.3%;

            --ring: 217.2 32.6% 17.5%;
        } */
    }

.without-ring {
	@apply focus:ring-0 focus:ring-offset-0;
}


